<template>
  <section id="create-meeting" class="mt-10">
    <div class="col-sm-12 col-lg-4">
      <v-sheet class="pa-5">
        <div class="font-weight-bold mb-5">SCHEDULE MEETING</div>
        <label class="caption">TYPE OF STUDENT</label>
        <v-select
          dense
          :items="$helpers.userLevel()"
          v-model="user_level"
          multiple
          chips
          clearable
          small-chips
        />
        <label class="caption">TOPIC</label>
        <v-text-field dense v-model="topic" />
        <label class="caption">AGENDA</label>
        <v-textarea dense rows="1" v-model="agenda" />
        <v-row class="mt-1">
          <v-col cols="12" lg="6" class="d-flex flex-column">
            <label class="caption">DATE</label>
            <input
              v-model="date"
              type="datetime-local"
              style="
                padding: 4px;
                border-bottom: 1px solid #999;
                outline: none;
                font-size: 14px;
                color: #fff;
              "
            />
          </v-col>
          <v-col cols="12" lg="6">
            <label class="caption">DURATION</label>
            <v-text-field
              type="number"
              dense
              v-model="duration"
              placeholder="Minutes"
            />
          </v-col>
        </v-row>

        <v-menu transition="slide-y-transition" bottom :rounded="0" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="purple" color="primary" dark v-bind="attrs" v-on="on">
              Save and
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="d-flex flex-column">
            <v-btn @click="goTo" text width="150">
              <v-icon left>mdi-arrow-right</v-icon>
              <div class="caption">Go to meetings</div>
            </v-btn>
            <v-btn text width="100">
              <v-icon left>mdi-plus</v-icon>
              <div class="caption">Add New</div>
            </v-btn>
          </div>
        </v-menu>
      </v-sheet>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => ({
    topic: "",
    agenda: "",
    duration: "",
    date: "",
    user_level: "",
  }),
  methods: {
    ...mapActions("admin", ["storeMeeting"]),

    goTo() {
      this.storeMeeting({
        topic: this.topic,
        agenda: this.agenda,
        start_time: this.date,
        duration: this.duration,
        user_level: this.user_level,
      }).then(() => {
        if (this.$errors("admin")) return;
        this.$router.push({ name: "Admin Meetings" });
      });
    },
  },
};
</script>
